import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { MuiTelInput } from 'mui-tel-input';
import { useState } from 'react';
import HeadPcImg from '../components/HeadPcImg';
import { getFirstAvailableSaturday } from '../components/QuoteWidget';
import './PrivateEvents.scss';

const PrivateEvents = () => {
  const [fName, setFName] = useState(localStorage.getItem('fName') || '');
  const [lName, setLName] = useState(localStorage.getItem('lName') || '');
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [eventDate, setEventDate] = useState(localStorage.getItem('eventDate') || getFirstAvailableSaturday());
  const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem('phoneNumber') || '');

  const onFieldChange = (saveCb, name, value) => {
    localStorage.setItem(name, value);
    saveCb(value);
  }

  return (
    <div className='private-events'>
      <HeadPcImg title='PRIVATE EVENTS' />
      <div className='quote'>
        <TextField
          size='small'
          className='quote-input input-first-name'
          label="FirstName"
          variant="outlined"
          value={fName}
          onChange={(e) => onFieldChange(setFName, 'fName', e.target.value)}
        />
        <TextField
          size='small'
          className='quote-input input-last-name'
          label="Last Name"
          variant="outlined"
          value={lName}
          onChange={(e) => onFieldChange(setLName, 'lName', e.target.value)}
        />

        <MuiTelInput
          size='small'
          label="Phone Number"
          className='quote-input input-phone-number'
          defaultCountry="US"
          value={phoneNumber}
          onChange={(value) => onFieldChange(setPhoneNumber, 'phoneNumber', value)}
        />

        <TextField
          size='small'
          label="Email"
          className='quote-input input-email'
          variant="outlined"
          value={email}
          onChange={(e) => onFieldChange(setEmail, 'email', e.target.value)}
        />

        <div className='break' />

        <DatePicker
          slotProps={{ textField: { size: 'small' } }}
          label='Event Date'
          className='quote-input input-date'
          defaultValue={dayjs(eventDate)}
          onChange={(date) => onFieldChange(setEventDate, 'eventDate', date)}
        />

        <TextField
          size='small'
          label="Date of Event"
          className='quote-input input-email'
          variant="outlined"
          value={email}
          onChange={(e) => onFieldChange(setEmail, 'email', e.target.value)}
        />

        <TextField
          size='small'
          label="Email"
          className='quote-input input-email'
          variant="outlined"
          value={email}
          onChange={(e) => onFieldChange(setEmail, 'email', e.target.value)}
        />
      </div>
    </div>
  );
}

export default PrivateEvents;
