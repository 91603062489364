import './HeaderPc.scss';

const HeaderPc = ({ children }) => {
  return (
    <div className='header-pc'>
      <div className='disclaimer' />
      <div className='black-spacer' />
      <div className='menu-wrap'>
        {children}
      </div>
    </div>
  );
}

export default HeaderPc;
