import "react-datepicker/dist/react-datepicker.css";
import HeadPcImg from "../components/HeadPcImg";
import './Home.scss';

const Home = () => {
  return (
    <div className='home'>
      <HeadPcImg title='PIZZA CATERING UNDER CONSTRUCTION' subTitle={'Get ready Los Angeles!'} />
      {/* <QuoteWidget /> */}
    </div>
  );
}

export default Home;
