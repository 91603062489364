import HeadImg from '../assets/img/photo/home-1.jpg';
import './HeadPcImg.scss';

const HeadPcImg = ({ title, subTitle }) => {
  return (
    <div className='head-pc-image'>
      <img src={HeadImg} alt="" />
      <div className='text-container'>
        <h1 className='text'>{title}</h1>
        {subTitle && <h2 className='text'>{subTitle}</h2>}
      </div>
    </div>
  );
}

export default HeadPcImg;
