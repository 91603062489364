import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Squash as Hamburger } from 'hamburger-react';
import React, { useState } from "react";
import { BrowserView, MobileView } from 'react-device-detect';
import {
  BrowserRouter,
  Link,
  Route,
  Routes
} from "react-router-dom";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import './App.scss';
import HeaderPc from './components/HeaderPc';
import MenuPc from './components/MenuPc';
import routes from './Routes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function App() {
  const [isOpen, setOpen] = useState(false)

  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className={isOpen ? "App menu-open" : "App"}>
          <div className="menu-wrap">
            <BrowserView>
              <HeaderPc>
                <MenuPc />
              </HeaderPc>
            </BrowserView>
            <MobileView>
              <Hamburger toggled={isOpen} toggle={setOpen} />
              <SlidingPane
                isOpen={isOpen}
                overlayClassName="menu-overlay"
                from="left"
                width="200px"
                onRequestClose={() => setOpen(false)}
              >
                {routes.map((route, i) => (
                  <Link key={i} to={route.path} className="menu-item">{route.menu}</Link>
                ))}
              </SlidingPane>
            </MobileView>
          </div>
        </div>

        <Routes>
          {routes.map((route, i) => (
            <Route key={i} path={route.path} element={route.element} />
          ))}
        </Routes>
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default App;
