import './Contacts.scss';

const Contacts = () => {
  return (
    <div className='contacts'>
      <h1>Contacts</h1>
    </div>
  );
}

export default Contacts;
