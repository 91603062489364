import './AboutUs.scss';

const AboutUs = () => {
  return (
    <div className='about-us'>
      <h1>About Us</h1>
    </div>
  );
}

export default AboutUs;
