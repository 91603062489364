import { Link, useLocation } from 'react-router-dom';
import routes from '../Routes';
import './MenuPc.scss';

import Logo from '../assets/img/logo/logo-bord.png';

const MenuPc = () => {
  const location = useLocation();

  console.log('location.pathname', location.pathname)

  const getActiveClass = (route) => {
    return location.pathname === route.path ? 'active' : '';
  }

  return (
    <div className='menu-pc'>
      {/* <div className={`menu-item ${getActiveClass(routes[0])}`}><Link to={routes[0].path} className='menu-link'>{routes[0].menu}</Link></div>
      <div className={`menu-item ${getActiveClass(routes[1])}`}><Link to={routes[1].path} className='menu-link'>{routes[1].menu}</Link></div>
      <div className={`menu-item partial ${getActiveClass(routes[1])}`} /> */}

      <img src={Logo} alt='logo' className='logo' />

      {/* <div className={`menu-item partial ${getActiveClass(routes[2])}`} />
      <div className={`menu-item ${getActiveClass(routes[2])}`}><Link to={routes[2].path} className='menu-link'>{routes[2].menu}</Link></div>
      <div className={`menu-item ${getActiveClass(routes[3])}`}><Link to={routes[3].path} className='menu-link'>{routes[3].menu}</Link></div> */}
    </div>
  );
}

export default MenuPc;
