import AboutUs from "./pages/AboutUs";
import Contacts from "./pages/Contacts";
import Home from "./pages/Home";
import PrivateEvents from "./pages/PrivateEvents";

const routes = [
  {
    path: "/",
    menu: 'Home',
    element: <Home />,
  },
  {
    path: "/private-events",
    menu: 'Private Events',
    element: <PrivateEvents />,
  },
  {
    path: "/acout-us",
    menu: 'About Us',
    element: <AboutUs />,
  },
  {
    path: "/contacts",
    menu: 'Contacts',
    element: <Contacts />,
  },
]

export default routes;
