import { useNavigate } from 'react-router-dom';
import './QuoteWidget.scss';

import { Button, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import dayjs from 'dayjs';
import { useState } from 'react';

export const getFirstAvailableSaturday = () => {
  const today = new Date();
  const currentDay = today.getDay(); // 0 (Domenica) to 6 (Sabato)

  // Calcola quanti giorni mancano al prossimo sabato
  const daysUntilSaturday = (6 - currentDay) % 7;

  // Crea una nuova data che rappresenta il prossimo sabato
  const nextSaturday = new Date(today);
  nextSaturday.setDate(today.getDate() + daysUntilSaturday);

  return nextSaturday;
}

const QuoteWidget = () => {
  const [eventDate, setEventDate] = useState(getFirstAvailableSaturday());
  const [peopleN, setPeopleN] = useState(40);

  const navigate = useNavigate();


  const getAQuoteHandler = (event) => {
    event.preventDefault();

    localStorage.setItem('dateEvent', eventDate);
    localStorage.setItem('peopleN', peopleN);

    navigate(`/private-events`);
  };

  return (
    <div className='quote-widget'>
      <form onSubmit={getAQuoteHandler}>
        <DatePicker
          slotProps={{ textField: { size: 'small' } }}
          label='Event Date'
          className='quote-input input-date'
          defaultValue={dayjs(eventDate)}
          onChange={(date) => setEventDate(date)}
        />
        <TextField
          size='small'
          className='quote-input input-people'
          type='number'
          defaultValue={peopleN}
          label="People #"
          variant="outlined"
          onChange={(e) => setPeopleN(e.target.value)}
        />
        <Button variant="contained" type='submit'>Get A Quote</Button>
      </form>
    </div>
  );
}

export default QuoteWidget;
